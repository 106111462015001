const UAParser = require("./ua-parser");
const pako = require("./pako.min");
const CryptoJS = require("crypto-js");

define([], function () {
  var sdkInstance;
  var vendorid;
  var vendorkey;
  var baseurl;

  function VLiveBaseSdk(id, key, baseUrl, subcriberId) {
    var returnObj;
    if (sdkInstance != undefined) {
      returnObj = sdkInstance;
    } else {
      vendorid = id;
      vendorkey = key;
      baseurl = baseUrl;

      this._runningIndex = 1;
      this._maxRows = 300;
      this._purgeThreshold = 10;
      // this._encryptionKey = "ki3d4km9zu9mdj17";
      this._encryptionKey = vendorkey;

      var userAgent = new UAParser();
      this._parsedUA = userAgent.getResult();

      this._subscriberId = getSubscriberId(subcriberId);
      this._devideId = getDeviceId(); // md5 hash of navigator.userAgent
      this._deviceType = "WEB";
      this._deviceOS = getDeviceOS(this); // navigator.userAgent - get OS
      this._osVersion = getOSVersion(this); // navigator.userAgent - get OS
      this._model = getDeviceModel(this); // navigator.platform
      this._manufacturer = getDeviceManufacturer(this); // navigator.vendor
      this._appVersion = getAppVersion(this); // navigator.userAgent - get Version/xx OR Chrome/xx OR Firefox/xx

      this.storageHandle = window["localStorage"];

      this.storageHandle.setItem(0, buildDelimiterRow(this));

      // console.log("Returned row is : " + this.storageHandle.getItem(0));

      sdkInstance = this;
      returnObj = sdkInstance;

      this._purgeStartRow = 0;
      this._purgeEndRow = 99;

      this._purgeTimer = setTimeout(function () {
        // console.log('Yee hoo, about to purge !!!');
        sdkInstance._purgeTimer = null;
        sdkInstance.sync();
      }, 1000 * 10 * 1);
    }

    return returnObj;
  }

  function getSubscriberId(inSubscriberId) {
    var subscriberId = "";
    if (inSubscriberId != undefined) {
      subscriberId = inSubscriberId;
    }

    return subscriberId;
  }

  function getDeviceId() {
    var baseInput = navigator.userAgent;

    var deviceid = JSON.stringify({ deviceid: baseInput });

    var hashdeviceid = CryptoJS.SHA1(deviceid);

    return hashdeviceid + "";
  }

  function getDeviceOS(sdkContext) {
    var os = "";
    if (sdkContext != undefined) {
      os =
        sdkContext._parsedUA.os.name != undefined
          ? sdkContext._parsedUA.os.name
          : "";
    }

    return os;
  }

  function getOSVersion(sdkContext) {
    var osVersion = "";

    if (sdkContext != undefined) {
      osVersion =
        sdkContext._parsedUA.os.version != undefined
          ? sdkContext._parsedUA.os.version
          : "";
    }

    return osVersion;
  }

  function getDeviceModel(sdkContext) {
    var deviceModel = "";

    if (sdkContext != undefined) {
      deviceModel =
        sdkContext._parsedUA.device.model != undefined
          ? sdkContext._parsedUA.device.model
          : window.navigator.platform;
    }

    return deviceModel;
  }

  function getDeviceManufacturer(sdkContext) {
    var deviceManufacturer = "";

    if (sdkContext != undefined) {
      deviceManufacturer =
        sdkContext._parsedUA.device.vendor != undefined
          ? sdkContext._parsedUA.device.vendor
          : window.navigator.vendor;
    }

    return deviceManufacturer;
  }

  function getAppVersion(sdkContext) {
    var appVersion = "";

    if (sdkContext != undefined) {
      appVersion =
        sdkContext._parsedUA.browser.name +
        "/" +
        sdkContext._parsedUA.browser.version;
    }

    return appVersion;
  }

  function buildDelimiterRow(sdkContext) {
    var delimiterRow = JSON.stringify({
      SubscriberId: sdkContext._subscriberId,
      DeviceID: sdkContext._devideId,
      DeviceType: sdkContext._deviceType,
      DeviceOS: sdkContext._deviceOS,
      OSVersion: sdkContext._osVersion,
      Model: sdkContext._model,
      Country: "IN",
      Manufacturer: sdkContext._manufacturer,
      AppVersion: sdkContext._appVersion
    });

    // console.log('Delimiter row : ' + delimiterRow);
    return delimiterRow;
  }

  function encryptData(plainData, key) {
    var C = CryptoJS;
    let plainText = C.enc.Utf8.parse(plainData);
    key = C.enc.Utf8.parse(key);
    var aes = C.algo.AES.createEncryptor(key, {
      mode: C.mode.ECB,
      padding: C.pad.Pkcs7,
      iv: key
    });
    var encrypted = aes.finalize(plainText);
    encrypted = C.enc.Base64.stringify(encrypted);
    encrypted = encode_url(encrypted);
    return encrypted;
  }

  // function decryptData(encryptedData, key) {
  //   var C = CryptoJS;
  //   var decode = decode_url(encryptedData);
  //   var encryptedData = C.enc.Base64.parse(decode);
  //   key = C.enc.Utf8.parse(key);
  //   var aes = C.algo.AES.createDecryptor(key, {
  //     mode: C.mode.ECB,
  //     padding: C.pad.Pkcs7,
  //     iv: key
  //   });
  //   var decrypted = aes.finalize(encryptedData);
  //   return C.enc.Utf8.stringify(decrypted);
  // }

  function encode_url(data) {
    // console.log(data);
    data = data.replace(/\+/g, "-");
    data = data.replace(/\//g, "_");
    data = data.replace(/=/g, ",");
    // console.log(data);
    return data;
  }

  function decode_url(data) {
    data = data.replace(/-/g, "+");
    data = data.replace(/_/g, "/");
    data = data.replace(/,/g, "=");
    return data;
  }

  function packageData(dataArray, encryptionKey) {
    if (dataArray != undefined) {
      var dataString = JSON.stringify(dataArray);

      var rawBody = encryptData(dataString, encryptionKey);

      var rawLen = rawBody.length;

      var bufBody = new Uint8Array(rawLen);
      for (var i = 0; i < rawLen; i++) {
        bufBody[i] = rawBody.charCodeAt(i);
      }

      var buf = pako.gzip(bufBody);

      var hashedData = CryptoJS.SHA1(dataString);

      var xhr = new XMLHttpRequest();

      // console.log('[vLiveWebSdkBase -> vendorid]', vendorid);
      // console.log('[vLiveWebSdkBase -> vendorkey]', vendorkey);

      // xhr.open('POST', "http://52.6.150.184/planetcast_dev/analytics/v1/analytics.php?vendorid=PLANETCAST&hash=" + hashedData);

      console.log(JSON.parse(localStorage.getItem("appConfig")))
      xhr.open(
        "POST",
        baseurl + "/analytics/v1/analytics?vendorid=" +
        vendorid +
        "&hash=" +
        hashedData
      );

      xhr.setRequestHeader("Content-Encoding", "gzip");
      xhr.setRequestHeader("Content-Type", "application/octet-stream");

      xhr.send(buf);
    }
  }

  VLiveBaseSdk.prototype = {
    put: function (input) {
      if (typeof input === "object") {
        if (this._runningIndex % this._purgeThreshold == 0) {
          this.storageHandle.setItem(
            this._runningIndex,
            buildDelimiterRow(this)
          );
          this._runningIndex++;
        }

        input["EventTimeStamp"] = Date.now();

        this.storageHandle.setItem(this._runningIndex, JSON.stringify(input));
        this._runningIndex++;

        if (this._runningIndex >= this._maxRows) {
          /* Trigger a purge. */
          this.sync();

          this.storageHandle.setItem(0, buildDelimiterRow(this));
          this._runningIndex = 1;
        }
      }
    },

    sync: function () {
      if (this._purgeTimer != null) {
        clearTimeout(this._purgeTimer);
        this._purgeTimer = null;
      }

      var dataArray = [];

      var startIndex = 0;

      var separatorRow = this.storageHandle.getItem(startIndex);

      while (separatorRow != null) {
        var index = 0;

        for (index = 0; index < this._purgeThreshold; index++) {
          var dataRow = this.storageHandle.getItem(index + startIndex);

          if (dataRow != null) {
            dataArray.push(JSON.parse(dataRow));

            if (!((index + startIndex) % this._purgeThreshold == 0)) {
              // var mergedObj = Object.assign(
              //   {},
              //   JSON.parse(separatorRow),
              //   JSON.parse(dataRow)
              // );
              /* mixpanel.track("Web SDK Event", mergedObj); */
            }

            this.storageHandle.removeItem(index + startIndex);
          } else {
            break;
          }
        }

        if (dataArray.length != 0) {
          packageData(dataArray, this._encryptionKey);

          startIndex += index;

          separatorRow = this.storageHandle.getItem(startIndex);

          if (separatorRow == null) {
            this._runningIndex = 0;
          }

          dataArray = [];
        } else {
          separatorRow = null;
        }
      }

      this._purgeTimer = setTimeout(function () {
        // console.log('Timer fired');

        sdkInstance._purgeTimer = null;
        sdkInstance.sync();
      }, 1000 * 10 * 1);
    }
  };

  // return VLiveBaseSdk;
  module.exports = VLiveBaseSdk;
});
